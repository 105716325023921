.ant-switch.status-switch {
  width: 32px;
  height: 16px;
  border-radius: 9px;
  margin-right: 8px;
  background: #456BE8;
  border: 1px solid #2755F0;
  box-sizing: border-box;
  box-shadow: 0 0 3px rgba(66, 66, 203, 0.2), 0 0 1px rgba(0, 0, 0, 0.1);
}

.ant-switch.status-switch.off {
  background: radial-gradient(93.75% 293.75% at 76.56% 96.87%, #f7b12f 0%, #ff7a00 100%);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 0 5px rgba(255, 149, 0, 0.5);
}

.ant-switch.status-switch.neutral {
  background: radial-gradient(93.75% 293.75% at 76.56% 96.87%, #e8e8e8 0%, #c9c9c9 100%);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 0 5px rgba(160, 160, 160, 0.5);
}

.ant-switch.status-switch .ant-switch-handle {
  top: 1px;
  left: calc(100% - 12px - 1px);
}

.ant-switch.status-switch.off .ant-switch-handle {
  left: 1px;
}

.ant-switch.status-switch.neutral .ant-switch-handle {
  left: 1px;
}
