/**********
* Globals
**********/
:root {
  --primary: #2755f0;
  --primary0: #f5f7ff;
  --primary100: #e6ecfe;
  --primary200: #c4d1fd;
  --primary300: #90a8f9;
  --primary400: #6887ee;
  --primary500: #456be8;
  --primary600: #2755f0;
  --primary700: #0e3fdd;
  --primary800: #072eab;
  --primary900: #031963;
  --primary1000: #030f38;
}

/**********
* Select
***********/
.ant-select-selector {
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
}

/**********
* Icon Button
***********/

/* Icon only button style */
.ant-btn-icon-only:not(.ant-btn-background-ghost) {
  padding: 6px !important;
  width: revert;
}

/* Ghost icon button style: no padding */
.ant-btn-icon-only.ant-btn-background-ghost {
  padding: 0px !important;
}

/**********
* Table header styling
***********/

/* Caps, font, border */
.ant-table-thead {
  font-size: 11px;
  text-transform: uppercase;
}

.ant-table-thead>tr>th {
  border-bottom: 1px rgb(211, 211, 211) solid;
}

/* If a table is sorted, darken font*/
.ant-table-thead>tr>.ant-table-column-sort {
  color: rgb(20, 20, 20);
}

/* Override active sort icon color from dark grey*/
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #397dd5;
}

/* Header padding. Padding is applied differently if a column has sorters */
.ant-table-thead>tr>th:not(.ant-table-column-has-sorters),
.ant-table-column-sorters {
  padding-bottom: 7px !important;
}

/* Ant theme styles don't apply to sortable tables. This override applies to them */
.ant-table-tbody>tr:hover {
  background: var(--primary0);
}

/**********
* Ant tabs
***********/

.ant-tabs-card .ant-tabs-tab {
  border: 0px !important;
}

.ant-tabs-card .ant-tabs-tab:not(.ant-tabs-tab-active) {
  background: transparent !important;
}

/**********
* Ant Select
***********/

.ant-select-dropdown {
  padding: 0;
  overflow: hidden;
}

.ant-select-dropdown .ant-select-item-option {
  border-bottom: 0.5px solid rgba(3, 3, 56, 0.1);
  min-height: 40px;
  font-size: 12px;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.ant-select-dropdown .ant-select-item-option-selected {
  background-color: var(--primary100);
  color: var(--primary);
}

.ant-select-dropdown .ant-select-item-option:hover {
  background-color: var(--primary0);
}
